import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './config/router'
import ElementUI from 'element-ui';
import customApi from '@/config/api'
import post from '@/config/index'
import { Swipe, SwipeItem } from 'vant';
import { Icon } from 'vant';
import 'element-ui/lib/theme-chalk/index.css';
import './style.css'
import '@/config/lib-flexible'
import '@/static/icon/iconfont.css'
import 'vant/lib/index.css';

Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Router)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$customApi = customApi
Vue.prototype.$httpRequest = post.post
Vue.prototype.$imgurl = post.baseURL

document.title = '河南尚信教育'

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
