<!-- 首页 -->
<template>
    <div ref="home" class='home'>
        <div class="slideshow">
            <van-swipe ref="swipe" :autoplay="3000" @change="change">
                <van-swipe-item v-for="item in configurations.banner_list" :key='item.id'>
                    <div class="fiche" :style="'height:' + height">
                        <img :src="$imgurl+item">
                        <!-- <div :class="{ 'div': 0 == initial }">
                            <p>源于自然，高于自然</p> 
                            <p>我们的充土技术，为您创造一个安全一舒适的生活环境</p>
                            <span>ORIGINATING FROM NATURE HIGHER THAN NATURE</span>
                        </div> -->
                    </div>
                </van-swipe-item>
                
                <template #indicator>
                    <div class="custom-indicator">
                        <p :class="{ 'p': index == initial }" v-for="(item, index) in 3" :key="item">
                            <span></span>
                        </p>
                    </div>
                </template>
            </van-swipe>
            <div v-if="swipe" @click="swipe.prev" class="controller1">
                <van-icon name="arrow-left" />
            </div>
            <div v-if="swipe" @click="swipe.next" class="controller2">
                <van-icon name="arrow" />
            </div>
        </div>
        <div :class="['product', 'Domlist', { 'Cartoon': Dom[1].flag }]">
            <header>
                <nav>
                    <p> 师资团队</p>
                    <!-- <h3>师资团队</h3> -->
                    <span></span>
                </nav>
                <!-- <div>
                    <div v-for="value in CateList" :key="value.id" @mouseenter="alteration('product', value.id)" @click="alteration('product', value.id)"
                        :class="{ 'div': product == value.id }">
                        <p>
                            <img :src="$imgurl+value.img" alt="">
                            <img :src="$imgurl+value.img_selected" alt="">
                        </p>
                        <span>{{ value.title }}</span>
                        <i>{{value.title_en}}</i>
                    </div>
                </div> -->
            </header>
            <main>
                <div v-for="(value,index) in GoodsList" v-if="index<=3" :key="value.id" >
                    <img :src="$imgurl+value.img" alt="">
                    <div style="width:80%;margin-left:10%">
                        <h5 style="">{{value.name}}</h5>
                        <p style="margin-top:10px;color:#687590">{{value.job}}</p>
                        <p class="jieshao" style="margin-top:0.3rem;line-height:20px;color:#687590">{{ value.intro }}</p>
                        <div style="text-align:center" class="jieshaos">
                            <img :src="$imgurl+value.qrcode" alt="" style="width:100px;height:100px;margin:30px 0 20px">
                            <div style="font-size:14px"> {{value.tel}}</div>
                        </div>
                        <div class="product_bot">
                            <el-popover
                            placement="right"
                            width="120"
                            trigger="click">
                                <div style="text-align:center;" >
                                    <img :src="$imgurl+value.qrcode" alt="" style="width:100px;height:100px;margin:10px 0 ">
                                    <p style="font-size:16px"> {{value.tel}}</p>
                                </div>
                                <el-button type="primary"  size="small" plain slot="reference" >联系我们</el-button>
                            </el-popover>
                        </div>
                        
                    </div>
                </div>
            </main>
            
            <button @click="skip('/engineering')" type="primary">更多+</button>
        </div>
        <div   :class="['ServiceItem', 'Domlist', { 'Cartoon': Dom[0].flag }]">
            <p style="color:white"> 服务项目 </p>
            <!-- <h3 style="color:white">服务项目</h3> -->
            <!-- <span></span> -->
            <div style="height:0.5rem"></div>
            <div style="display: flex;justify-content: space-around;">
                <article>
                    <img :src="$imgurl+serveList[0]">
                    <h3>学历提升</h3>
                    <!-- <p>公司拥有多支研发团队，支持来样定制。技术过硬的施工团队全国施工</p> -->
                </article>
                <article>
                    <img :src="$imgurl+serveList[1]">
                    <h3>职业资格</h3>
                    <!-- <p>工厂直营新型夯土墙技术配方 夯砼 UHPC超强夯土板 夯土抹装材料 老土墙 夯土砖</p> -->
                </article>
                <article>
                    <img :src="$imgurl+serveList[2]">
                    <h3>职业技能</h3>
                    <!-- <p>夯土项目全程技术指导 施工人员专业化技术培训 项目施工管理</p> -->
                </article>
                <article>
                    <img :src="$imgurl+serveList[3]">
                    <h3>职称评审</h3>
                    <!-- <p>与全国多个工厂合作，支持夯土新型材料贴牌代加工</p> -->
                </article>
            </div>
          <div class="hotzy" style="">
            <div style="display:flex;justify-content: space-between;">
                <div>
                    <h3 style="margin-top:10px">热门专业</h3>
                   
                </div>
                <div style="margin-top:1px;margin-right:70px" class="ssearch">
                        <el-input
                        placeholder="请输入内容"
                        v-model="input4" @change="getCla">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                        </el-input>
                </div>
                 
            </div>
            <div style="margin-top:20px;font-size:12px">
                        各种专业报考相关资讯信息，可查看详情了解更多
                    </div>
            <div style="overflow:hidden;margin-top:10px">
                <div class="rmli" v-for="value in CateList" :key="value.id">{{value.title}}</div>
               
            </div>
             <button @click="skip('/product')" type="primary">更多+</button>
           </div>
        </div>
         <div :class="['News', 'Domlist', { 'Cartoon': Dom[4].flag }]">
            <header>
                <p>政策资讯 </p>
                <!-- <h3>政策资讯</h3> -->
                <span></span>
            </header>
            <!-- <nav>
                <p @mouseenter="alteration('Newsindex', 0)" :class="{ 'p': Newsindex == 0 }">
                    <i>公司新闻</i>
                    <span></span>
                </p>
                <p @mouseenter="alteration('Newsindex', 1)" :class="{ 'p': Newsindex == 1 }">
                    <i>行业资讯</i>
                    <span></span>
                </p>
                <p @mouseenter="alteration('Newsindex', 2)" :class="{ 'p': Newsindex == 2 }">
                    <i>常见问答</i>
                    <span></span>
                </p>
            </nav> -->
            <main style="display:flex;flex-wrap:wrap">
                <div  v-for="(value,index) in articleList" :key="value.id" class="mmlisli" style="width:50%;margin-top:20px">
                    <div class="fiche" @click="skip('/case',value.id)">
                            <!-- <div class="img">
                                <p :style="'background: url(' + $imgurl+value.img + ') no-repeat;'"></p>
                                <img style="height: 100%;" :src="$imgurl+value.img">
                            </div> -->
                            <div class="text" style="box-shadow:0 10px 10px 3px rgba(0, 0, 0, 0.1) !important;margin-bottom:4px">
                               <div style="overflow:hidden">
                                <div style="background:rgb(60,141,247);float:left;width:25px;color:white;text-align:center;border-radius:5px;font-size:14px">{{index+1}}</div>
                                 <span style="float:right;color:#b8b8b8;font-size:14px;margin-top:3px">{{value.create_time}}</span>
                               </div>
                                <h1 style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{value.title}}</h1>
                                <p class="jieshaoss" style="height:44px;line-height:22px">{{value.intro}}</p>
                            </div>
                        </div>
                </div>
                
            </main>
            <button @click="skip('/journalism')" type="primary">更多+</button>
        </div>
        
        <div class='contact' style="display:flex">
            <!-- <header>
                <p>Policy Advice </p>
                <h3>政策资讯</h3>
                <span></span>
            </header> -->
            <div class="sdds">
                <img src="../static/maps.png" style="width:5.6rem;height:5.6rem" alt="">
            </div>
            <main class="main" style="width:50%;padding: 20px 5%">
                <div>
                    <p><span>联系人<i>*</i></span><input v-model="from.username" type="text" placeholder="请输入姓名"></p>
                    <p><span>电话号码<i>*</i></span><input v-model="from.mobile" type="text" placeholder="请输入电话号码"></p>
                    <!-- <p><span>邮箱<i>*</i></span><input v-model="from.email" type="text" placeholder="请输入邮箱"></p> -->
                </div>
                <div>
                    <span>咨询内容 (例如:公共营养师证书报考流程)</span>
                    <textarea v-model="from.need" style="resize: none;" placeholder="请输入咨询内容"></textarea>
                </div>
                <div>
                    <button @click="submit">提交</button>
                </div>
            </main>
        </div>
        <div :class="['introduce', 'Domlist', { 'Cartoon': Dom[2].flag }]">
            <p> 公司简介</p>
            <!-- <h3>公司简介</h3> -->
            <span></span>
            <!-- <header>
                <div>
                    <p>
                        <count-to v-if="Dom[2].dom" :useEasing="false" :endVal="200" :duration="2"></count-to>
                        <b>+<i>家</i></b>
                    </p>
                    世界500强企业合作
                </div>
                <div>
                    <p>
                        <count-to v-if="Dom[2].dom" :useEasing="false" :endVal="10000" :duration="2"></count-to>
                        <b>+<i>项</i></b>
                    </p>
                    精品案例
                </div>
                <div>
                    <p>
                        <span>NO.<count-to v-if="Dom[2].dom" :useEasing="false" :endVal="10"
                                :duration="2"></count-to></span>
                    </p>
                    精品案例
                </div>
                <div>
                    <p>
                        <count-to v-if="Dom[2].dom" :useEasing="false" :endVal="400" :duration="2"></count-to>
                        <b>+<i>项</i></b>
                    </p>
                    专业设计团队
                </div>
            </header> -->
            <main>
                <div >
                    <h2 style="text-align: left;width: 100%;margin-bottom: 20px;">河南尚信教育科技有限公司 </h2>
                    <span style="line-height:30px">创立于2016年，是一家专业做教育信息咨询公司，集团是经工商部门教育部门批准注册成立的教育性质集团性公司公司主要服务于学历提升、职业资格、职业技能、职称评审、出国留学人社部证书，根据学员的实际情况为学员制定专业的课程，形成了完善的课程体系，尚信教育集团公司以技能教育为主，满足社会各阶层，多个行业的学习需求。尚信教育奉行“让客户身边没有教育难题”的教育理念，致力于构建于面向大众,符合大众需要，为大众服务的教育公司。</span>
                </div>
                <p class="img">
                    <img src="../static/home/5.png">
                </p>
            </main>
        </div>
        <!-- <div :class="['case', 'Domlist', { 'Cartoon': Dom[3].flag }]">
            <header>
                <p> Engineering case </p>
                <h3>工程案例</h3>
                <span></span>
            </header>
            <main>
                <van-swipe ref="verticalSwipe" :autoplay="5000" :style="'height:' + verticalH" vertical @change="vertical">
                    <van-swipe-item v-for="(item, index) in caseList" :key="item.id">
                        <div class="img" @click="skip('/case',item.id)">
                            <p :style="'background: url(' + $imgurl+item.img + ') no-repeat;'"></p>
                            <img :style="'height:' + verticalH" :src="$imgurl+item.img">
                            <span :class="{ 'span': initial1 == index }">{{ item.title }}</span>
                        </div>
                    </van-swipe-item>
                    <template #indicator>
                        <div class="custom-indicator">
                            <p @click="verticalSwipe.swipeTo(index)" :class="{ 'p': index == initial1 }"
                                v-for="(item, index) in caseList.length" :key="item">
                                <span></span>
                            </p>
                        </div>
                    </template>
                </van-swipe>
            </main>
            <button @click="skip('/engineering')" type="primary">more+</button>
        </div> -->
        <div :class="['Cooperation', 'Domlist', { 'Cartoon': Dom[4].flag }]">
            
        </div>
        
    </div>
</template>

<script>
// :duration="2"：数字过渡的时间为2秒 :separator="' '"：数字使用空格隔开 :decimals="0"：数字显示0位小数 :startVal="0"：数字从0开始递增 :useEasing="false"：数字递增不使用缓动
import CountTo from 'vue-countup-v2'

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {
        CountTo
    },
    data() {
        //这里存放数据
        return {
            height: '11.5rem',
            verticalH: '8rem',
            width: 300,
            arrow: 'never',
            initial: 0,
            initial1: 0,
            initial2: 0,
            product: 0,
            Newsindex: 0,
            proportion: .3,
            swipe: '',
            verticalSwipe: '',
            NewsSwipe: '',
            CateList:[],
            GoodsList:[],
            caseList:[],
            articleList:[],
            Dom: [{}, {}, {}, {}, {}, {}],
            configurations:{},
            serveList:[],
            input4:'',
            from: {
                mobile: '',
                username: '',
                email: '',
                need: '',
            }
        };
    },
    //计算属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    methods: {
        skip(route, id) {
            if(route=='/product'){
                this.input4 = ''
            }
            this.$router.push({
                name: route,
                params: { id, }
            })
        },
        alteration(variable, index) {
            this[variable] = index
            if(variable=='product'){
                this.getGoodsList(this.product)
            }
        },
        change(index) {
            this.initial = index
        },
        vertical(index) {
            this.initial1 = index
        },
        Newschange(index) {
            this.initial2 = index
        },
        submit() {
            if (!this.from.username) {
                this.$message.error('请输入姓名');
                return
            }
            if (!this.from.mobile) {
                this.$message.error('请输入手机号');
                return
            }
            
            this.$httpRequest('/index/setUserNeed', this.from).then(res => {
                this.$message({
                    message: '提交成功',
                    type: 'success'
                });
            })
        },
        resize() {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1000) {
                this.height = '11.5rem'
                this.arrow = 'never'
            } else {
                this.height = '23rem'
                this.arrow = 'always'
            }
            if (screenWidth > 1440) {
                this.verticalH = '8rem'
                this.proportion = .3
            } else if (screenWidth < 700) {
                this.verticalH = '200px'
                this.proportion = .6
            } else {
                this.verticalH = '10rem'
                if (screenWidth < 1200 && screenWidth > 700) {
                    this.proportion = .54
                } else {
                    this.proportion = .4
                }
            }
            this.Dompush()
            this.width = screenWidth * this.proportion
        },
        scroll() {
            const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
            this.Dom.forEach((value, index) => {
                if (value.top < scrollPos + innerHeight - 100) {
                    this.Dom[index].flag = true
                    this.Dom[index].dom = true
                } else {
                    this.Dom[index].dom = false
                }
            })
        },
        Dompush() {
            this.Dom = []
            let list = [...document.getElementsByClassName('Domlist')]
            list.forEach(value => {
                this.Dom.push({
                    flag: false,
                    dom: false,
                    top: value.offsetTop
                })
            })
            this.scroll()
        },
        getCla(e){
            if(e){
                 this.$router.push({
                    name: '/product',
                    params: { search:e }
            })
            }
        },
        getCateList(){
            this.$httpRequest('/index/getCateList').then(res => {
                this.CateList=res.data
            })
        },
        getGoodsList(id) {
            this.$httpRequest('/index/getTeacherList').then(res => {
                this.GoodsList = res.data
            })
        },
        caseLists() {
            this.$httpRequest('/index/caseList',).then(res => {
                this.caseList = res.data.data
                this.caseList.splice(4)
            })
        },
         configs() {
            this.$httpRequest('/index/config').then(res => {
                this.configurations=res.data
            })
        },
        articleLists() {
            this.$httpRequest('/index/articleList').then(res => {
                this.articleList = res.data
                // this.articleList.splice(4)
            })
        },
        serveLists() {
            this.$httpRequest('/index/serviceList').then(res => {
                this.serveList = res.data
                // this.articleList.splice(4)
            })
        },
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getCateList()
        this.configs()
        this.articleLists()
        this.getGoodsList()
        this.serveLists()
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        
        this.resize()
        window.addEventListener('scroll', () => {
            this.scroll()
        });
        window.addEventListener('resize', () => {
            this.resize()
        });
        this.swipe = this.$refs.swipe
        this.verticalSwipe = this.$refs.verticalSwipe
        this.NewsSwipe = this.$refs.NewsSwipe
        if (localStorage.getItem('path')) {
            let top = document.getElementsByClassName(localStorage.getItem('path'))[0].offsetTop
            this.$customApi.scrollToTop(top - 100)
            localStorage.removeItem('path')
        }
    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() {
        if (localStorage.getItem('path')) {
            let top = document.getElementsByClassName(localStorage.getItem('path'))[0].offsetTop
            this.$customApi.scrollToTop(top - 100)
            localStorage.removeItem('path')
        }
     }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
@media screen and (min-width: 1601px) {
.home>.product>main>div{
    height:5.5rem !important
}
}
@media screen and (min-width: 2000px) {
.home>.product>main>div{
    height:4.5rem !important
}
}
@media screen and (max-width: 1600px) {
    .hotzy{
        &>button {
            width: 112px !important;
            height: 38px !important;
            font-size: 16px !important;
            border-radius: 3px !important;
            margin: 30px auto 0 !important;
            background: #1b5fad;
        }
    }
    .News {
        padding: 60px 0 !important;

        &>button {
            width: 112px !important;
            height: 38px !important;
            font-size: 16px !important;
            border-radius: 3px !important;
            margin: 30px auto 0 !important;
        }

        &>main {

            .controller1,
            .controller2 {
                width: 50px !important;
                height: 50px !important;
                font-size: 22px !important;
            }

            // .van-swipe-item:nth-child(1) {
            //     .fiche {
            //         padding: 0 50px 0 0 !important;
            //     }
            // }

            // .van-swipe-item:nth-last-child(1) {
            //     .fiche {
            //         padding: 0 0 0 50px !important;
            //     }
            // }

            .fiche {
                padding: 0 25px !important;

                &>.text {
                    min-height: 140px !important;
                    border-radius: 0 0 10px 10px !important;
                    padding: 20px 20px !important;

                    &>span {
                        font-size: 18px !important;
                    }

                    &>h1 {
                        font-size: 16px !important;
                        margin: 15px 0 !important;
                    }

                    &>p {
                        font-size: 14px !important;
                        margin-bottom: 5px !important;
                    }
                }

                &>.img {
                    height: 240px !important;
                    border-radius: 10px 10px 0 0 !important;

                    &>p {
                        filter: blur(3px) !important;
                    }
                }
            }
        }

        &>nav {
            width: 84% !important;
            padding: 20px 0 50px !important;

            &>p {
                margin-left: 40px !important;
                font-size: 18px !important;

                &>span {
                    height: 2px !important;
                    margin-top: 10px !important;
                }
            }
        }

        &>header {
            margin: 0 auto 50px !important;

            &>span {
                width: 24px !important;
                min-width: 24px !important;
                height: 2px !important;
                margin: 16px 0 0 !important;
            }

            &>h3 {
                font-size: 24px !important;
            }

            &>p {
                font-size: 45px !important;
            }
        }
    }

    .Cooperation {
        // padding: 120px 0 !important;

        &>main {
            width: 80% !important;

            &>div {
                margin-bottom: 20px !important;

                &>img {
                    width: 100%;
                }
            }
        }

        &>header {
            margin: 0 auto 50px !important;

            &>span {
                width: 24px !important;
                min-width: 24px !important;
                height: 2px !important;
                margin: 16px 0 0 !important;
            }

            &>h3 {
                font-size: 24px !important;
            }

            &>p {
                font-size: 45px !important;
            }
        }
    }

    .case {
        padding: 120px 0 !important;

        &>button {
            width: 112px !important;
            height: 38px !important;
            font-size: 16px !important;
            border-radius: 3px !important;
            margin: 36px auto 0 !important;
        }

        &>main {
            margin: 20px auto 0 !important;

            .custom-indicator {
                border-radius: 8px !important;
                padding: 5px 0 !important;

                &>.p {
                    &>span {
                        height: 20px !important;
                    }
                }

                &>p {
                    width: 16px !important;
                    height: 22px !important;

                    &>span {
                        width: 12px !important;
                        height: 12px !important;
                        border-radius: 6px !important;
                    }
                }
            }

            .img {
                &>span {
                    font-size: 20px !important;
                }
            }
        }

        &>header {
            margin: 0 auto 50px !important;

            &>span {
                width: 24px !important;
                min-width: 24px !important;
                height: 2px !important;
                margin: 16px 0 0 !important;
            }

            &>h3 {
                font-size: 24px !important;
            }

            &>p {
                font-size: 45px !important;
            }
        }
    }

    .introduce {
        margin: 70px 0 120px !important;

        &>main {
            height: 400px !important;
            border-radius: 10px !important;
            box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1) !important;

            &>div {
                padding: 20px 50px !important;
                font-size: 14px !important;

                &>button {
                    width: 112px !important;
                    height: 38px !important;
                    font-size: 16px !important;
                    border-radius: 3px !important;
                    margin: 36px auto 0 !important;
                }
            }
        }

        &>span {
            width: 24px !important;
            height: 2px !important;
            margin: 16px auto 0 !important;
        }

        &>h3 {
            font-size: 24px !important;
        }

        &>p {
            font-size: 45px !important;
        }

        &>header {
            padding: 50px 0 120px !important;

            &>div {
                font-size: 16px !important;

                &>P {
                    font-size: 30px !important;

                    &>b {
                        margin-left: 10px !important;

                        &>i {
                            font-size: 16px !important;
                        }
                    }

                    &>span {
                        font-size: 60px !important;
                    }
                }
            }
        }
    }

    .ServiceItem {
        // background: url('../static/home/backsever.png') no-repeat no-repeat;
        // background-size:100% 100% ;
        &>span {
            width: 24px !important;
            height: 2px !important;
            margin: 16px auto 0 !important;
        }

        &>h3 {
            font-size: 24px !important;
        }

        &>p {
            font-size: 45px !important;
        }
    }

    .slideshow {
        .fiche {
            &>div {
                font-size: 20px !important;
                &>span{
                    font-size: 16px !important;
                }
            }
        }
    }

    .product {

        &>button {
            width: 112px !important;
            height: 38px !important;
            font-size: 16px !important;
            margin: 36px auto 0 !important;
        }

        &>header {

            &>div {
                &>div {
                    &>p {
                        width: 56px !important;
                        height: 56px !important;
                    }

                    &>span {
                        font-size: 14px !important;
                    }

                    &>i {
                        font-size: 10px !important;
                    }
                }
            }

            &>nav {
                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 24px !important;
                }

                &>p {
                    font-size: 45px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
     .jieshao{
                    display: none;
                }
                .jieshaos{
                    display: none;
                }
    .contact{
        header {
                flex-wrap: wrap;
                padding: 20px 0 70px !important;

                &>div {
                    width: 50% !important;
                    font-size: 12px !important;
                    margin-top: 20px;

                    &>P {
                        font-size: 20px !important;

                        &>b {
                            &>i {
                                font-size: 12px !important;
                            }
                        }

                        &>span {
                            font-size: 40px !important;
                        }
                    }
                }
            }
    }
    .home {

        &>.introduce {
            margin: 30px 0 60px !important;

            &>main {
                height: 300px !important;
                &>div {
                    font-size: 12px !important;
                }
            }

            &>h3 {
                font-size: 17px !important;
            }

            &>p {
                font-size: 30px !important;
            }

            &>header {
                flex-wrap: wrap;
                padding: 20px 0 70px !important;

                &>div {
                    width: 50% !important;
                    font-size: 12px !important;
                    margin-top: 20px;

                    &>P {
                        font-size: 20px !important;

                        &>b {
                            &>i {
                                font-size: 12px !important;
                            }
                        }

                        &>span {
                            font-size: 40px !important;
                        }
                    }
                }
            }
        }

        &>.product {
            padding: 30px 20px !important;

            &>main {
                flex-wrap: wrap !important;
                justify-content: space-between !important;

                &>div {
                    width: 48% !important;
                    height: 10rem !important;
                    margin-top: 60px !important;
                    &>div {

                        &>p {
                            font-size: 14px !important;

                        }

                        &>span {
                            font-size: 10px !important;
                        }
                    }
                }
            }

            &>header {
                flex-direction: column !important;
                align-items: flex-start !important;

                &>div {
                    width: 100% !important;
                    min-width: 100% !important;
                    justify-content: flex-start !important;
                    margin-top: 20px;
                    flex-wrap: wrap;

                    // &>div:nth-child(3),
                    // &>div:nth-child(1) {
                    //     border-bottom: 1px solid #e5e5e5;
                    // }

                    // &>div:nth-child(2) {
                    //     border: 1px solid #e5e5e5;
                    //     border-top: 0;
                    // }

                    // &>div:nth-child(5) {
                    //     border-left: 1px solid #e5e5e5;
                    //     border-right: 1px solid #e5e5e5;
                    // }

                    &>div {
                        width: calc(100% / 3) !important;
                        padding: 20px 10px !important;

                        &>span {
                            font-size: 12px !important;
                        }

                        &>i {
                            font-size: 10px !important;
                        }

                        &>p {
                            width: 48px !important;
                            height: 48px !important;
                        }
                    }
                }

                &>nav {
                    &>h3 {
                        font-size: 17px !important;
                    }

                    &>p {
                        font-size: 30px !important;
                    }
                }
            }
        }

        &>.ServiceItem {
            padding: 30px 0 !important;

            &>h3 {
                font-size: 17px !important;
            }

            &>p {
                font-size: 30px !important;
            }

            &>div {
                flex-wrap: wrap;

                &>article:nth-child(1) {
                    border-bottom: 1px solid #e5e5e5;
                    border-right: 1px solid #e5e5e5;
                    margin-top: 30px !important;
                }

                &>article:nth-child(2) {
                    border-bottom: 1px solid #e5e5e5;
                    margin-top: 30px !important;
                }

                &>article:nth-child(3) {
                    border-right: 1px solid #e5e5e5;
                }

                &>article {
                    width: 50% !important;
                    margin-top: 0px !important;
                    padding: 20px 20px !important;

                    &>h3 {
                        font-size: 16px !important;
                    }

                    &>p {
                        font-size: 12px !important;
                    }

                    &>img {
                        width: 80px !important;
                    }
                }
            }
        }

        &>.slideshow {
            height: 23rem !important;

            .controller1,
            .controller2 {
                width: 35px !important;
                height: 35px !important;
                font-size: 22px !important;
            }

            .el-carousel__indicators {
                display: none !important;
            }

            .fiche {
                align-items: normal !important;

                &>img {
                    max-height: 80% !important;
                    width: 100% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .home {

        .News {
            padding: 30px 0 !important;

            &>nav {
                padding: 15px 0 40px !important;

                &>p {
                    margin-left: 30px !important;
                    font-size: 14px !important;
                }
            }

            &>main {

                .controller1,
                .controller2 {
                    width: 30px !important;
                    height: 30px !important;
                    font-size: 16px !important;
                }

                // .van-swipe-item:nth-child(1) {
                //     .fiche {
                //         padding: 0 36px 0 0 !important;
                //     }
                // }

                // .van-swipe-item:nth-last-child(1) {
                //     .fiche {
                //         padding: 0 0 0 36px !important;
                //     }
                // }

                .fiche {
                    padding: 0 18px !important;

                    &>.text {
                        min-height: 130px !important;
                        border-radius: 0 0 10px 10px !important;
                        padding: 30px 15px !important;

                        &>span {
                            font-size: 16px !important;
                        }

                        &>h1 {
                            font-size: 16px !important;
                            margin: 20px 0 !important;
                        }

                        &>p {
                            font-size: 12px !important;
                            // margin-bottom: 20px !important;
                        }
                    }

                    &>.img {
                        height: 160px !important;
                        border-radius: 10px 10px 0 0 !important;

                        &>p {
                            filter: blur(3px) !important;
                        }
                    }
                }
            }

            &>header {
                margin: 0 auto 30px !important;

                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 20px !important;
                }

                &>p {
                    font-size: 30px !important;
                }
            }
        }

        .Cooperation {
            // padding: 70px 0 !important;

            &>main {
                width: 90% !important;

                &>div {
                    width: calc(100% / 3.2) !important;
                }
            }

            &>header {
                margin: 0 auto 50px !important;

                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 20px !important;
                }

                &>p {
                    font-size: 30px !important;
                }
            }
        }

        .case {
            padding: 70px 0 !important;

            &>main {
                margin: 10px auto 0 !important;

                .custom-indicator {
                    border-radius: 6px !important;
                    padding: 5px 0 !important;

                    &>.p {
                        &>span {
                            height: 15px !important;
                        }
                    }

                    &>p {
                        width: 12px !important;
                        height: 17px !important;

                        &>span {
                            width: 10px !important;
                            height: 10px !important;
                            border-radius: 5px !important;
                        }
                    }
                }

                .img {
                    &>p {
                        filter: blur(5px);
                    }

                    &>span {
                        font-size: 15px !important;
                    }
                }
            }

            &>header {
                margin: 0 auto 50px !important;

                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 20px !important;
                }

                &>p {
                    font-size: 30px !important;
                }
            }
        }

        &>.introduce {
            margin: 30px 0 60px !important;

            &>main {
                height: 400px !important;

                &>div {
                    padding: 20px !important;
                    font-size: 12px 10% !important;
                }

                &>.img {
                    display: none !important;
                }
            }

            &>h3 {
                font-size: 17px !important;
            }

            &>p {
                font-size: 30px !important;
            }

            &>header {
                padding: 20px 0 70px !important;

                &>div {
                    font-size: 10px !important;

                    &>P {
                        font-size: 16px !important;

                        &>b {
                            &>i {
                                font-size: 10px !important;
                            }
                        }

                        &>span {
                            font-size: 30px !important;
                        }
                    }
                }
            }
        }

        .slideshow {
            .controller1 {
                left: 10px !important;
                opacity: 1 !important;
            }

            .controller2 {
                right: 10px !important;
                opacity: 1 !important;
            }

            .fiche {

                &>div {
                    font-size: 16px !important;
                    &>span{
                    font-size: 14px !important;
                }
                }
            }
        }

        .product {
            &>main {
                
                &>div {
                    height: 14rem !important;

                    &>div {
                        &>p {
                            font-size: 12px !important;

                        }

                        &>span {
                            font-size: 8px !important;
                        }
                    }
                }
                .jieshao{
                    font-size: 10px !important;
                      -webkit-line-clamp: 3 !important;
                }
                .jieshaos{
                    display: none;
                }
                .product_bot{
                    width: 80% !important;
                    margin-left: 10% !important; 
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .home {
        .News {
            &>nav {
                padding: 15px 0 30px !important;

                &>p {
                    margin-left: 20px !important;
                    font-size: 13px !important;
                }
            }

            &>main {
                width: 95% !important;

                .controller1,
                .controller2 {
                    width: 25px !important;
                    height: 25px !important;
                    font-size: 16px !important;
                }

                // .van-swipe-item:nth-child(1) {
                //     .fiche {
                //         padding: 0 20px 0 0 !important;
                //     }
                // }

                // .van-swipe-item:nth-last-child(1) {
                //     .fiche {
                //         padding: 0 0 0 20px !important;
                //     }
                // }

                .fiche {
                    padding: 0 10px !important;

                    &>.text {
                        border-radius: 0 0 10px 10px !important;
                        padding: 10px 10px !important;

                        &>span {
                            font-size: 14px !important;
                        }

                        &>h1 {
                            font-size: 14px !important;
                            margin: 15px 0 !important;
                        }

                        &>p {
                            font-size: 10px !important;
                            // margin-bottom: 15px !important;
                        }
                    }

                    &>.img {
                        height: 160px !important;
                        border-radius: 10px 10px 0 0 !important;

                        &>p {
                            filter: blur(3px) !important;
                        }
                    }
                }
            }
        }
    }
    &>.introduce {
            &>main {
                height: 200px !important;
            }
        }
}

@keyframes gradual {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes fluctuate {
    0% {
        transform: translateY(0%);
    }

    25% {
        transform: translateY(-100%);
    }

    50% {
        transform: translateY(0%);
    }

    75% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(0%);
    }
}

.home {
    width: 100%;
    user-select: none;
    overflow: hidden;

    &>.News {
        width: 100%;
         background: rgb(245, 249, 255);
        padding: 1.5rem 0;

        &>button {
            background: #1b5fad;
            width: 1.4rem;
            height: .475rem;
            color: #fff;
            font-size: .2rem;
            border-radius: .0375rem;
            margin: .45rem auto 0;
            display: block;
        }

        &>main {
            width: 84%;
            margin: 0 auto;
            position: relative;

            .controller1,
            .controller2 {
                width: .625rem;
                height: .625rem;
                background-color: #00000070;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .275rem;
                color: #fff;
                transition: all .3s;
            }

            .controller1 {
                left: 0%;
                transform: translate(-130%, -50%);
            }

            .controller2 {
                right: 0%;
                transform: translate(130%, -50%);
            }

            // .van-swipe-item:nth-child(1) {
            //     .fiche {
            //         padding: 0 1.25rem 0 0;
            //     }
            // }

            // .van-swipe-item:nth-last-child(1) {
            //     .fiche {
            //         padding: 0 0 0 1.25rem;
            //     }
            // }

            .fiche {
                width: 100%;
                padding: 0 .625rem;
                box-sizing: border-box;

                &>.text:hover {

                    &>p,
                    &>span {
                        color: #1b5fad;
                    }

                    &>h1 {
                        color: #222;
                    }
                }

                &>.text {
                    width: 100%;
                    min-height: 2.5rem;
                    background-color: #fff;
                    border-radius: 0 0 .125rem .125rem;
                    overflow: hidden;
                    padding: .425rem .25rem;
                    box-sizing: border-box;

                    &>span {
                        color: #ccc;
                        font-size: .225rem;
                        font-weight: bold;
                        transition: all .3s;
                    }

                    &>h1 {
                        font-size: .225rem;
                        color: #353432;
                        margin: .3rem 0;
                    }

                    &>p {
                        color: #b8b8b8;
                        font-size: .175rem;
                        margin-bottom: .3rem;
                        transition: all .3s;
                        overflow: hidden;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                &>.img {
                    width: 100%;
                    height: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border-radius: .125rem .125rem 0 0;
                    overflow: hidden;

                    &>p {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-size: 100% 100% !important;
                        z-index: 1;
                        filter: blur(.0375rem);
                    }

                    &>img {
                        max-width: 100%;
                        max-height: 100%;
                        z-index: 2;
                    }
                }
            }
        }

        &>nav {
            width: 20rem;
            margin: 0 auto;
            padding: .25rem 0 .625rem;
            display: flex;
            justify-content: flex-end;

            &>.p {
                color: #333;

                &>i {
                    animation: fluctuate .6s linear 0s 1;
                }

                &>span {
                    width: 100%;
                }
            }

            &>p {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: .5rem;
                font-size: .225rem;
                color: #666;

                &>span {
                    width: 0%;
                    height: .025rem;
                    margin-top: .125rem;
                    background: #1b5fad;
                    transition: all .3s;
                }
            }
        }

        &>header {
            width: 20rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto .625rem;

            &>span {
                display: block;
                width: .3rem;
                min-width: .3rem;
                height: .025rem;
                background: #1b5fad;
                margin: .2rem 0 0;
            }

            &>h3 {
                font-size: .3rem;
                text-align: center;
                font-weight: 100;
            }

            &>p {
                font-size: .5625rem;
                font-weight: bold;
                text-align: center;
                color: #1b5fad;
            }
        }
    }

    &>.Cooperation {
        width: 100%;
        padding: 0.5rem 0;

        &>main {
            width: 60%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &>div:hover {
                border: 1px solid #cccccc00;

                &>p:nth-child(3),
                &>p:nth-child(1) {
                    width: 100%;
                }

                &>p:nth-child(2),
                &>p:nth-child(4) {
                    height: 100%;
                }
            }

            &>div {
                width: 18%;
                position: relative;
                border: 1px solid #ccc;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: .25rem;
                padding: 1px 0;

                &>p {
                    position: absolute;
                    transition: all .3s;
                    z-index: 2;
                    background-color: #1b5fad;
                }

                &>p:nth-child(1) {
                    width: 0%;
                    height: 1px;
                    left: 50%;
                    top: -1px;
                    transform: translateX(-50%);
                }

                &>p:nth-child(2) {
                    width: 1px;
                    height: 0%;
                    position: absolute;
                    right: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &>p:nth-child(3) {
                    width: 0%;
                    height: 1px;
                    position: absolute;
                    left: 50%;
                    bottom: -1px;
                    transform: translateX(-50%);
                }

                &>p:nth-child(4) {
                    width: 1px;
                    height: 0%;
                    position: absolute;
                    left: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &>img {
                    width: 80%;
                    z-index: 1;
                }
            }
        }

        &>header {
            width: 20rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto .625rem;

            &>span {
                display: block;
                width: .3rem;
                min-width: .3rem;
                height: .025rem;
                background: #1b5fad;
                margin: .2rem 0 0;
            }

            &>h3 {
                font-size: .3rem;
                text-align: center;
                font-weight: 100;
            }

            &>p {
                font-size: .5625rem;
                font-weight: bold;
                text-align: center;
                color: #1b5fad;
            }
        }
    }

    &>.case {
        width: 100%;
         background: rgb(245, 249, 255);
        padding: 1.5rem 0;

        &>button {
            background: #1b5fad;
            width: 1.4rem;
            height: .475rem;
            color: #fff;
            font-size: .2rem;
            border-radius: .0375rem;
            margin: .45rem auto 0;
            display: block;
        }

        &>main {
            width: 20rem;
            margin: .25rem auto 0;

            .custom-indicator {
                position: absolute;
                right: .125rem;
                top: 50%;
                transform: translateY(-50%);
                background: #00000070;
                border-radius: .1rem;
                padding: .0625rem 0;

                &>.p {
                    &>span {
                        height: .25rem;
                    }
                }

                &>p {
                    width: .2rem;
                    height: .275rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>span {
                        width: .15rem;
                        height: .15rem;
                        background-color: #fff;
                        border-radius: .075rem;
                        transition: all .3s;
                    }
                }
            }

            .img {
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                &>p {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background-size: 100% 100% !important;
                    filter: blur(.0625rem);
                }

                &>img {
                    height: 100%;
                    z-index: 2;
                }

                &>.span {
                    left: 50%;
                    opacity: 1;
                }

                &>span {
                    font-size: .25rem;
                    color: #fff;
                    font-weight: bold;
                    z-index: 3;
                    position: absolute;
                    left: 0%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 1s;
                    opacity: 0;
                }
            }
        }

        &>header {
            width: 20rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto .625rem;

            &>span {
                display: block;
                width: .3rem;
                min-width: .3rem;
                height: .025rem;
                background: #1b5fad;
                margin: .2rem 0 0;
            }

            &>h3 {
                font-size: .3rem;
                text-align: center;
                font-weight: 100;
            }

            &>p {
                font-size: .5625rem;
                font-weight: bold;
                text-align: center;
                color: #1b5fad;
            }
        }
    }

    &>.introduce {
        width: 100%;
        margin: .875rem 0 1.5rem;
        opacity: 0;

        &>main {
            width: 85%;
            height: 5rem;
            margin: 0 auto;
            border-radius: .125rem;
            overflow: hidden;
            box-shadow: 0 .025rem .375rem rgba(0, 0, 0, 0.1);
            display: flex;

            &>div {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: .25rem 10%;
                box-sizing: border-box;
                font-size: .2rem;

                &>span {
                    min-width: 250px;
                    overflow: hidden;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 11;
                    -webkit-box-orient: vertical;
                }
            }

            &>.img {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                &>img{
                    height: 100%;
                }
            }
        }

        &>span {
            display: block;
            margin: 0 auto;
            width: .3rem;
            height: .025rem;
            background: #1b5fad;
            margin: .2rem auto 0;
        }

        &>h3 {
            font-size: .3rem;
            text-align: center;
            font-weight: 100;
        }

        &>p {
            font-size: .5625rem;
            font-weight: bold;
            text-align: center;
            color: #1b5fad;
        }

        &>header {
            width: 100%;
            padding: .625rem 0 1.5rem;
            display: flex;

            &>div {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #999;
                font-size: .2rem;

                &>P {
                    font-size: .375rem;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    color: #333;

                    &>b {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: .125rem;
                        color: #1b5fad;

                        &>i {
                            font-size: .2rem;
                            color: #999;
                        }
                    }

                    &>span {
                        font-size: .75rem;
                    }
                }
            }
        }
    }

    &>.product {
        width: 100%;
        padding: 100px 0;
        box-sizing: border-box;
        background: rgb(245, 249, 255);

        &>button {
            background: #1b5fad;
            width: 1.4rem;
            height: .475rem;
            color: #fff;
            font-size: .2rem;
            border-radius: .0375rem;
            margin: .45rem auto 0;
            display: block;
        }

        &>main {
            width: 22rem;
            display: flex;
            justify-content: space-around;
            margin: 20px auto;
            &>div {
                width: 4.5rem;
                height: 6.5rem;
                background-color: #fff;
                border-radius: 10px;
                // overflow: hidden;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &>div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    // align-items: center;

                    &>* {
                        // text-align: center;
                        transform: translateY(50px);
                        transition: all .3s;
                    }

                    &>p {
                        font-size: .2rem;

                    }

                    &>span {
                        font-size: .175rem;
                        margin-top: .375rem;
                    }
                }

                &>img {
                    position: absolute;
                    top:-40px;
                    left: 30px;
                    width: 80px;
                    height:80px;
                    border-radius: 50%;
                    transition: all .3s;
                }
            }
            .product_bot{
                position: absolute;
                bottom: 80px;
                width:50%;
                height: 30px;
                line-height: 30px; 
                text-align: center;
                // border: 1px solid #6EAFFF;
                margin-left: 25%;
                // color: #2184FF;
                font-size: 12px;
            }
        }

        &>header {
            max-width: 20rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            margin: 0 auto;

            &>div {
                width: 16rem;
                display: flex;
                justify-content: space-around;
                margin-top: 50px;

                &>div {
                    width: 1.625rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;
                    padding: .25rem 0;
                    text-align: center;

                    &>span {
                        font-size: .2rem;
                        margin-top: 20px;
                    }

                    &>i {
                        font-size: .15rem;
                        margin-top: 20px;
                    }

                    &>p {
                        width: .85rem;
                        height: .85rem;
                        border: 1px solid #e5e5e5;
                        border-radius: 50%;
                        position: relative;
                        transition: all .3s;

                        &>img {
                            transition: all .4s;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }

                        &>img:nth-child(1) {
                            width: 60%;
                            height: 60%;
                            z-index: 1;
                            transition: all .5s;
                        }

                        &>img:nth-child(2) {
                            width: 0%;
                            height: 0%;
                            z-index: 2;
                        }
                    }
                }

                &>.div {
                    color: #1b5fad;

                    &>p {
                        background: #1b5fad;
                        border: 1px solid #1b5fad;

                        &>img:nth-child(2) {
                            width: 60%;
                            height: 60%;
                        }

                        &>img:nth-child(1) {
                            opacity: 0;
                        }
                    }
                }
            }

            &>nav {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                &>span {
                    display: block;
                    width: .3rem;
                    min-width: 24px;
                    height: .025rem;
                    background: #1b5fad;
                    margin: 16px 0 0;
                }

                &>h3 {
                    font-size: .3rem;
                    text-align: center;
                    font-weight: 100;
                }

                &>p {
                    font-size: .5625rem;
                    font-weight: bold;
                    text-align: center;
                    color: #1b5fad;
                }
            }
        }
    }

    &>.Cartoon {
        animation: gradual .8s linear 0s 1;
        animation-fill-mode: forwards;
    }

    &>.ServiceItem {
        width: 100%;
        padding: 0.5rem 0 1rem 0.2rem;
        // opacity: 0;
        background:url('../static/home/backsever.png') no-repeat no-repeat;
        background-size:100% 100%;
        &>div {
            
            

            &>article:hover {
                transform: scale(1.1);
                box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
                border: 0;

                &>p {
                    color: #1b5fad;
                }

                &>h3 {
                    color: #1b5fad;
                }
            }

            &>article {
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 30px 20px 1.5rem;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                transition: all .3s;
                background: #fff;

                &>p {
                    font-size: .1875rem;
                    color: #888;
                    text-align: center;
                    transition: all .3s;
                }

                &>h3 {
                    font-size: .225rem;
                    margin: .125rem auto;
                    text-align: center;
                    color: #5185c0;
                }

                &>img {
                    width:3rem;
                }
            }
        }

        &>span {
            display: block;
            margin: 0 auto;
            width: .3rem;
            height: .025rem;
            background: #1b5fad;
            margin: .2rem auto 0;
        }

        &>h3 {
            font-size: .3rem;
            text-align: center;
            font-weight: 100;
        }

        &>p {
            font-size: .5625rem;
            font-weight: bold;
            text-align: center;
            color: #1b5fad;
        }
    }

    &>.slideshow:hover {
        .controller1 {
            left: .125rem;
            opacity: 1;
        }

        .controller2 {
            right: .125rem;
            opacity: 1;
        }
    }

    &>.slideshow {
        width: 100%;
        height: 11.5rem;
        position: relative;

        .controller1,
        .controller2 {
            width: .625rem;
            height: .625rem;
            background-color: #00000070;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .275rem;
            color: #fff;
            transition: all .3s;
            opacity: 0;
        }

        .controller1 {
            left: -100%;
            transform: translateY(-50%);
        }

        .controller2 {
            right: -100%;
            transform: translateY(-50%);
        }

        .custom-indicator {
            display: flex;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            &>.p {
                &>span {
                    left: 0;
                    transition: all 3s linear;
                }
            }

            &>p {
                min-width: 20px;
                width: .25rem;
                min-height: 3px;
                height: .0375rem;
                background-color: #00000070;
                margin: 0 5px;
                position: relative;
                overflow: hidden;

                &>span {
                    width: 100%;
                    height: 100%;
                    background-color: #ccc;
                    position: absolute;
                    left: -100%;
                    top: 0;
                }
            }
        }

        .fiche {
            width: 100%;
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            position: relative;

            &>.div {
                top: 50%;
                color: #ffffff;
            }

            &>div {
                position: absolute;
                left: 50%;
                top: 90%;
                transition: all .8s;
                z-index: 10;
                transform: translate(-50%, -50%);
                color: #ffffff00;
                font-size: .4rem;
                &>p{
                    margin-top: 10px;
                }
                &>span{
                    display: block;
                    font-size: .3rem;
                    margin-top: 20px;
                }
            }

            &>img {
                width: 100%;
                min-height: 100%;
                display: block;
            }
        }
    }
}
.rmli{
    width: 68px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border: 1px solid #687590;
    border-radius: 30px;
    margin-top: 20px;
    margin-right: 15px;
    float: left;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding:  0 10px;
}
.hotzy{
    width:85%;
    padding:30px 0;
    margin-left:7.5%;
    &>button{
        background: #1b5fad;
        width: 1.4rem;
        height: 0.475rem;
        color: #fff;
        font-size: 0.2rem;
        border-radius: 0.0375rem;
        margin: 0.45rem auto 0;
        display: block;
    }
}
.jieshao{
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.jieshaoss{
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.contact {
    width: 100%;
    padding: 30px 0;
    // min-height: 100vh;
    box-sizing: border-box;
    // background: white;

    &>main {
        width: 100%;
        padding-top: 30px;
        background-color: #fff;

        &>div:nth-child(2) {
            display: block;

            &>span {
                display: block;
                margin: 20px 0 10px;
            }

            &>textarea {
                background: hsla(0, 0%, 80%, .15);
                width: 100%;
                height: 2.5rem;
                padding: .125rem;
                box-sizing: border-box;
                font-size: .2rem;
                border: 0px;
            }
        }

        &>div {
            max-width: 18rem;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &>button {
                background: #1b5fad;
                width: 1.4rem;
                height: .475rem;
                color: #fff;
                font-size: .2rem;
                border-radius: .0375rem;
                margin: .25rem 0;
            }

            &>p {
                width: 3.75rem;
                padding-left: .0625rem;
                margin-top: .25rem;

                &>span {
                    margin-bottom: 5px;
                    display: block;

                    &>i {
                        color: red;
                    }
                }

                &>input {
                    background: hsla(0, 0%, 80%, .15);
                    width: 100%;
                    height: .55rem;
                    display: block;
                    flex: 1;
                    color: #333;
                    font-size: .2rem;
                    padding-left: 10px;
                    box-sizing: border-box;
                }
            }
        }
    }

    &>div {
        // max-width: 18rem;
        padding: 20px;
        margin: 0 auto;

        &>img {
            width: 100%;
            display: block;
            margin: 20px 0;
        }

        &>div {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            font-size: .1875rem;
            margin: .25rem 0;

            &>p:nth-last-child(1) {
                margin-right: 0;
            }

            &>p {
                width: 25%;
                min-width: 120px;
                margin-top: .25rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                &>span {
                    margin: 10px 0;
                }

                &>img {
                    width: .625rem;
                }
            }
        }

        &>p {
            width: 80%;
            text-align: center;
            font-size: .2rem;
            margin: 0 auto;
        }
    }

    &>p {
        font-size: .175rem;
        text-align: center;
        margin: .125rem 0;
    }

    &>h1 {
        font-size: .325rem;
        text-align: center;
        margin: .25rem 0;
    }

    &>img {
        width: 100%;
    }
}
@media screen and (max-width: 550px) {
    .ssearch{
        margin-right:10px !important;
        width:150px;
    }
    .mmlisli{
        width: 100% !important;
    }
    
    .contact {
        .sdds{
            display: none;
        }
        &>main {
            width: 100% !important;
            padding: 0 !important;
            &>div:nth-child(2) {

                &>textarea {
                    height: 200px !important;
                    padding: 10px !important;
                    font-size: 14px !important;
                }
            }

            &>div {
                width: 95% !important;

                &>p {
                    width:8rem;
                    &>input {
                        height: 40px !important;
                        font-size: 14px !important;
                    }
                }
                button{
                    width:4rem;
                    height:2rem;
                    font-size: 17px;
                    margin-top: 20px;
                }
            }
        }

        &>div {
            width: 95% !important;
            padding: 0px !important;

            &>div {
                font-size: 15px !important;

                &>p {

                    &>img {
                        width: 30px !important;
                    }
                }
            }

            &>p {
                width: 100% !important;
                font-size: 14px !important;
            }
        }

        &>p {
            font-size: 12px !important;
        }

        &>h1 {
            font-size: 22px !important;
        }
    }
}
</style>